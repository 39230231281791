import styled from "styled-components"
import breakpoints from "./breakpoints"
import { semibold16, semibold18, bold18, bold32, bold40, bold28, bold16, bold36, bold20, bold64, bold80, bold24, bold72 } from "./typography"

const HeroText = styled.div`
    max-width: 130.4rem;
    text-align: center;
    width: 100vw;
    padding: 0 1.6rem;
    padding: 3rem;
    border-radius: 1rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 67.2rem;
        width: 85vw;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        width: 84rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        width: 95rem;
    }

    a { 
        background: var(--light-purple-dark);
        border: 2px solid var(--white);
        border-bottom: 2px solid var(--white);
        border-radius: 10px;
        padding: 1.2rem;
        color: var(--white);
        display: block;
        min-width: 16rem;
        ${semibold16};
        line-height: 1.6rem;
        margin: 1.6rem auto 0;
        position: relative;
        font-weight: 700;
        /* top:2rem; */
        text-decoration: none;
        white-space: nowrap;
        width: auto;
        &:first-of-type {
            margin-top: 2.4rem;
        }
        &:last-of-type {
            margin-top: 2.4rem;
        }
        &.learn-more {
            background: #00000036;
            ${bold20}
            border-radius: 30px;
            color: var(--white);
            @media screen and (min-width: ${breakpoints.sm}px) {
                width: 22.5rem;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                /* width: 18.5rem; */
                ${bold28}
            }

            &:visited {
                color: var(--white);
                background-color: transparent;
                border-bottom: 2px solid var(--white);
            }
        }
        &:visited {
            color: var(--white);
            background-color: transparent;
            border-bottom: 2px solid var(--white);
        }
        &:hover, 
        &:focus {
            border: 2px solid var(--white);
            border-bottom: 2px solid var(--white);
            cursor: pointer;
            color: var(--white);
            background: #00000036;
        }
        &:disabled {
            background: var(--light-green-darkest);
            cursor: auto;
        }

        @media screen and (min-width: ${breakpoints.sm}px) {
            margin: 1.6rem 1.6rem 0;
            display: inline-block;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            margin: 2.4rem 1.6rem 0;
        }
        

        &.telephone {
            padding: 0.8rem;
            background: var(--light-green-dark);
            display: block;
            text-align: center;
            margin: 2rem auto;
            display: fle;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            align-content: center;
            @media screen and (min-width: ${breakpoints.sm}px) {
                width: 30rem;
            }

            @media screen and (min-width: ${breakpoints.md}px) {

            }
            svg {
                width: 2.4rem;
                height: 2.4rem;
            }
            span {
                ${bold24};
                margin-left: 1.4rem;
            }
        }
    }

    blockquote {
        opacity: 0.8;
        border-radius: 1rem;
        p{
            opacity: 1;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            margin-top: 3.2rem;
            margin-bottom: 2.8rem;
            border-radius: 1rem;
            width: 70%;
            
        }
        cite {
            @media screen and (min-width: ${breakpoints.md}px) {
                width: 70%;
                margin: 1.6rem auto 0;
            }
        }
    }

    .rhap_container {
        box-shadow: 0 0 0 0;
        background: none;
    }
`

const HeroVideo = styled.div`
    max-width: 130.4rem;
    text-align: center;
    width: 100vw;
    padding: 0 1.6rem;
    /* background: #ffffffad; */

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 67.2rem;
        width: 70vw;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        width: 64.5rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        width: 85rem;
    }
`

const HeroHeader = styled.h1`
    color: var(--white);
    margin: 0;
    ${props => props.isLander ? bold40 : props.isGift ? bold40 : props.isLightSpace ? bold36 : props.isLightSpace ? bold36 : bold64};
    /* ${props => props.isGift && 'margin-bottom: 4rem'}; */
    
    @media screen and (min-width: ${breakpoints.sm}px) {
        ${props => props.isLander ? bold36 : bold64};
        margin-bottom: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        ${props => props.isLander ? bold40 : bold72};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        ${props => props.isLander ? bold64 : bold80};
    }
`

const HeroHeaderImage = styled.img`
    margin: 0 auto 2rem auto;
    display: block;
    width: 70%;
    @media screen and (min-width: ${breakpoints.sm}px) {
        margin-bottom: 2.4rem;
        width: 70%;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        width: 50%;
        margin: 0 auto 4rem auto;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
    }
`

const HeroDescription = styled.p`
    color: var(--white);
    margin: 2.4rem 0 0;
    ${props => props.isGift ? bold24 : bold20};
    ${props => props.isGift && 'margin-top: 5.6rem;margin-bottom: 5.6rem'};
    @media screen and (min-width: ${breakpoints.md}px) {
        margin: 2.4rem 4rem 0;
        ${props => props.isGift ? bold18 : bold32};
        ${props => props.isGift && 'margin-top: 5.6rem;margin-bottom: 5.6rem'};
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        ${props => props.isGift && 'margin-top: 5.6rem;margin-bottom: 5.6rem'};
        font-weight: 500;
        font-size: 4.6rem;
        line-height: 5.2rem;
    }

    span {
        text-transform: uppercase;
        display: block;
        font-size: 1.8rem;
    }
`

const HeroList = styled.ul`
    color: var(--white);
    list-style-position: inside;
    list-style-type: none;
    padding: 0;
    text-align: center;
    ${semibold18}
    margin-bottom: 2.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
        margin-bottom: 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }
`
const HeroListItem = styled.li`

        ${bold16}
        @media screen and (min-width: ${breakpoints.sm}px) {
            display: inline;
            list-style-type: circle;
            list-style-position: outside;
            margin: 0 2rem;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            ${bold28}
        }
`

export { HeroText, HeroHeader, HeroHeaderImage, HeroVideo, HeroDescription, HeroList, HeroListItem }