import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"

const StyledAboutKarina = styled.section`
    ${baseGridStyles}
    position: relative;
 
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            margin: 4rem 0;
            grid-column-start: 2;
            grid-column-end: 5;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            margin: 6rem auto;
            grid-column-start: 2;
            grid-column-end: 5;
        }

    figure {
        grid-column: span 3;
        grid-column-start: 1;
        grid-column-end: 5;
        display: flex;
        justify-content: center;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column-start: 3;
            grid-column-end: 5;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column-start: 3;
            grid-column-end: 6;
        }

        
    }

    div {
        grid-column: span 4;
        text-align: center;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 6 / 11;
            text-align: left;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 6 / 11;
            text-align: left;
        }

        h2{
            margin-bottom: 0.8rem;
            margin-top: 0;
            @media screen and (min-width: ${breakpoints.md}px) {
                margin-top: 0;
            }
        }
        
        p{
            margin-top: 1.6rem;
            @media screen and (min-width: ${breakpoints.md}px) {
                display: flex;
                padding-left: 0;
            }
            a {
                @media screen and (min-width: ${breakpoints.md}px) {
                    background-color: var(--white);
                    color: var(--light-purple-dark)!important;
                    justify-content: left;
                    padding-left: 0;
                }
            }
        }

        img {
            background-color: #ede7f6;
        }
    }
`
const StyledAboutArticle = styled.article`
    ${baseGridStyles}
    .repsonsive-video {
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 12;
        }

    }

`
const StyledAboutHeader = styled.header`
    grid-column: span 4;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 2 / 12;
    }


    h1 {
        text-align: center;
    }
`
const StyledAboutKarinaVideo = styled.div`
    grid-column: span 4;
    ${baseGridStyles}
    padding: 0;
    margin: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
        margin: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0;
        margin: 0;
    }

    > div {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 4 / 10;
        }
    }
    
`

const StyledAboutContent = styled.div`
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: 4 / 10;
    }
    p{
        margin-top: 1.6rem;
    }

    p span.alignleft {
        @media screen and (min-width: ${breakpoints.sm}px) {
            width: 220px!important;
            float: left;
            margin-right: 1.6rem;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            width: 280px!important;
        }
    }
`
const StyledAboutKarinaHomepage = styled.section`
    ${baseGridStyles}
    position: relative;
    figure {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 1 / 12;
        }
        img {
            @media screen and (min-width: ${breakpoints.md}px) {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        
        ${props => props.breakpoints.md || breakpoints.l && 'position: absolute;top: 0;left: 0;'}
    }

    div {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 1 / 12;
        }
    }

`

export {
    StyledAboutKarina,
    StyledAboutArticle,
    StyledAboutHeader,
    StyledAboutKarinaVideo,
    StyledAboutContent,
    StyledAboutKarinaHomepage
} 