import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  ArticleDate,
} from "../styledComponents/article"

import {
  StyledAboutArticle,
  StyledAboutHeader,
  StyledAboutKarinaVideo,
  StyledAboutContent
} from "../styledComponents/aboutKarina"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ReactVideoPlayer } from "../components/reactPlayer"
import HeroBanner from "../components/HeroBanner"
import { HeroDescription, HeroText } from "../styledComponents/heroBanner"
import { StyledAnchorLink } from "../styledComponents/button"

const About = ({ data, location }) => {
  const pageTitle = data.wpPage.title
  const pageContent = data.wpPage.content

  return (
    <Layout displayNavBorder location={location}>
      <SEO 
      title="About Karina Grant"
      description="Karina was introduced to Louise Hay and breath-work at age 12. Her passion for energetic health and well-being has inspired her to share the power of energy therapy and healing with as many people as possible"
      url={data.wpPage.uri}
      />
      
      <StyledAboutArticle itemScope itemType="http://schema.org/Article">
        <StyledAboutHeader>
          <h1 itemProp="headline">{pageTitle}</h1>
          <ArticleDate offScreen dateTime={data.wpPage.dateGmt}>Last updated on {data.wpPage.date}</ArticleDate>
        </StyledAboutHeader>

        <StyledAboutKarinaVideo>
          <ReactVideoPlayer 
            videoURL='https://karinagrant.s3.eu-west-1.amazonaws.com/videos/karinagrant-video.mp4' 
            title="Karina Grant Video - About page "
          />
        </StyledAboutKarinaVideo>

        {!!pageContent && (
          <StyledAboutContent itemProp="articleBody">
            {parse(pageContent)}
          </StyledAboutContent>
          
        )}
        
      </StyledAboutArticle>
      <HeroBanner
          image="https://karinagrant.s3.eu-west-1.amazonaws.com/images/karina-on-stage-wide-audience-min.jpg"
          backgroundPosition="0 0"
          largeBackgroundPosition="0 -260px"
          largeBackgroundSize="100%"
          type="karinaonstage"
        >
          <HeroText>
          </HeroText>
        </HeroBanner>

        <StyledAboutArticle>
          <StyledAboutContent>
          {parse(data.wpPage.aboutKarina.aboutKarinaBottom)}
          </StyledAboutContent>
        </StyledAboutArticle>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "about" }) {
      title
      content
      uri
      date(formatString: "MMMM DD, YYYY")
      dateGmt
      aboutKarina {
        aboutKarinaBottom
      }
    }
  }
`
