import styled from "styled-components"
import breakpoints from "../breakpoints"
import { bold16, bold18, bold24, bold32, bold36 } from "../typography"

const StyledHeroBanner = styled.section`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  50% 0;
  background-color: #953491;
  width: 100%;
  height: ${props => props.type === 'emailCapture' ? '80vh' : props.type === 'karinaonstage' ? '50vh' :  "calc(100vh - 2rem)"};
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  
  &:before {
      content: " ";
      display: block;
      width: 100vw;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      ${props => props.type === 'emailCapture' ? 'background: rgb(244,239,255);background: linear-gradient(90deg, rgba(244,239,255,0.623686974789916) 0%, rgba(244,239,255,0.727328431372549) 100%);'
    : props.type === 'homepage' || props.type === 'gift' || props.type === 'pat' || props.type === 'shm' ? 'background: rgb(125,23,154);background: radial-gradient(circle, rgba(125,23,154,1) 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,1) 100%);'
      : props.type === 'lightspace' || props.type === 'freeTalk' || props.type === 'karinaonstage' ? ''
        : props.type === 'hand-to-heart' ? 'background: #fff'
          : props.type === 'testimonial' ? 'background: rgb(255 147 0);background: radial-gradient(circle, rgb(125, 23, 154) 0%, rgba(255, 255, 255, 0) 100%, rgb(255, 255, 255) 100%);'
            : 'background: rgb(70,0,255);background: linear-gradient(90deg, rgba(70,0,255,0.562062324929972) 0%, rgba(70,0,255,0.8337710084033614) 32%, rgba(70,0,255,0.8785889355742297) 49%, rgba(70,0,255,0.8813900560224089) 62%, rgba(70,0,255,0.8421743697478992) 72%, rgba(70,0,255,0.7021183473389356) 100%)'};
  }
  @media screen and (max-device-width: 1136px) and (max-device-height: 640px) and (min-device-height: 560px){
    height: ${props => props.type === 'emailCapture' ? '80vh' : props.type === 'karinaonstage' ? '50vh' : "100vh"};
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    height: ${props => props.type === 'emailCapture' ? 'calc(100vh - 18.7rem)' : props.type === 'karinaonstage' ? '50vh' : "calc(100vh - 7.4rem)"};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    height: ${props => props.type === 'emailCapture' ? 'calc(100vh - 18.7rem)' : props.type === 'karinaonstage' ? '50vh' : "calc(100vh - 4.2rem)"};
  }

  ul li { 
    blockquote p {
      color: var(--white);
      text-shadow: 1px 3px 2px #474747;
      ${bold18}
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold24}
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        ${bold36}
      }
    }
    cite {
      color: var(--white);
      text-shadow: 1px 3px 2px #474747;
      ${bold18}
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold18}
      }
    }
  }
`

const StyledAnimationContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
`

const ChildWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 5.8rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  content: "";
  width: 100vw;


`

export {
  StyledHeroBanner,
  StyledAnimationContainer,
  ChildWrapper,
}
